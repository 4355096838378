import React, { useEffect, useState } from "react";
import { useXhr } from "hooks/useXhr";
import { Button } from "react-bootstrap";
import { useParams } from 'react-router-dom';
import html2canvas from "html2canvas";

//images
import Logo from "../../assets/images/logos/Logo.png"
import Facebook from "../../assets/images/logos/Facebook.png"
import LinkedIn from "../../assets/images/logos/LinkedIn.png"
import Instagram from "../../assets/images/logos/Instagram.png"
import Youtube from "../../assets/images/logos/Youtube.png"

function HtmlPage() {
    //States
    const [signature, setSignature] = useState(null);
    const [banners, setBanners] = useState(null);
    const [links, setLinks] = useState(null);
    const [kvkk, setKvkk] = useState(null);
    const [signatureWidth, setSignatureWidth] = useState(null);
    const [isCopied, setIsCopied] = useState(false);
    const [signatureStatus, setSignatureStatus] = useState("all");
    const [image, setImage] = useState({
        signature: null,
        facebook: null,
        linkedin: null,
        instagram: null,
        youtube: null
    });
    const [base64, setBase64] = useState({
        banner: null
    });

    //Hooks
    const { customXhr } = useXhr();
    const { id } = useParams();

    //Get Functions
    const getSignature = async () => {
        const formData = new FormData();
        formData.append('module', 'signatures');
        formData.append('action', 'getone');
        formData.append('id', id);

        const response = await customXhr(formData);

        if (response) {
            setSignature(response.data[0]);
            getBanners();
        };
    };

    const getBanners = async () => {
        const formData = new FormData();
        formData.append('module', 'banners');
        formData.append('action', 'list');

        const response = await customXhr(formData);

        if (response) {
            setBanners(response);
            getLinks();
        };
    };

    const getLinks = async () => {
        const formData = new FormData();
        formData.append('module', 'links');
        formData.append('action', 'list');

        const response = await customXhr(formData);

        if (response) {
            setLinks(response);
            getKvkk();
        };
    };

    const getKvkk = async () => {
        const formData = new FormData();
        formData.append('module', 'kvkk');
        formData.append('action', 'list');

        const response = await customXhr(formData);

        if (response) {
            setKvkk(response[0]);
            getSignatureWidth();
        };
    };

    const getSignatureWidth = async () => {
        const formData = new FormData();
        formData.append('module', 'signature_width');
        formData.append('action', 'list');

        const response = await customXhr(formData);

        if (response) {
            setSignatureWidth(Number(response[0].signatureWidth));
        };
    };

    //Copy Functions
    const simulateCtrlA = () => {
        try {
            const event = new Event('keydown', {
                bubbles: true,
                cancelable: true
            });
            event.ctrlKey = true;
            event.key = 'a';
            event.code = 'KeyA';
            event.keyCode = 65;

            document.dispatchEvent(event);

            // Metin seçimi için `document.execCommand` kullanabiliriz
            document.execCommand('selectAll', false, null);
            simulateCtrlC();
        } catch (error) {
            // Hata varsa alert ile kullanıcıya bildir
            alert('Bir hata oluştu: ' + error.message);
        };
    };

    const simulateCtrlC = () => {
        // Metni seçmek için
        const range = document.createRange();
        range.selectNode(document.body);
        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);

        // Kopyalama işlemi için execCommand kullan
        const success = document.execCommand('copy');

        // Seçimi temizle
        selection.removeAllRanges();

        // Kopyalama işleminin sonucuna göre alert göster
        if (success) {
            setIsCopied(true);
        } else {
            alert('Kopyalama başarısız!');
        };
    };

    //Create Image Functions
    const convertBannerToBase64 = (url) => {
        fetch(url)
            .then((response) => response.blob())
            .then((blob) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    setBase64(prevState => ({
                        ...prevState,
                        banner: reader.result
                    }));
                };
                reader.readAsDataURL(blob);
            })
            .catch((error) => {
                console.error('Görüntü yüklenirken bir hata oluştu:', error);
            });
    };

    const generateImageFromHTML = (htmlCode, field) => {
        const tempDiv = document.createElement('div');
        tempDiv.style.position = 'absolute';
        tempDiv.style.left = '-9999px';
        tempDiv.style.fontFamily = "Roboto, sans-serif";
        tempDiv.innerHTML = htmlCode;

        document.body.appendChild(tempDiv);

        html2canvas(tempDiv, {
            scale: 1,
        }).then((canvas) => {
            const imgData = canvas.toDataURL("image/png");
            setImage(prevState => ({
                ...prevState,
                [field]: imgData
            }));
            setSignatureStatus(field);
            document.body.removeChild(tempDiv);
        });
    };

    //Other Function
    const formatPhoneNumber = (phoneNumber) => {
        return phoneNumber.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, "$1 $2 $3 $4");
    };

    //useEffects
    useEffect(() => {
        getSignature();
    }, []);

    useEffect(() => {
        if (signature && links && signatureWidth && Logo && Facebook && LinkedIn && Instagram && Youtube) {
            if (signatureStatus === "all") {
                generateImageFromHTML(
                    `
                        <div style="display: flex; width: ${signatureWidth}px; padding: 20px 0;">
                            <div style="flex: 1; width: ${Math.floor(signatureWidth / 2) - 1}px; padding: 0 15px; border-right: 2px solid #676767; font-size: 14px; text-align: left;">
                                <h4 style="margin: 0; width: ${Math.floor(signatureWidth / 2) - 1}px; font-size: 24px;">${signature.name1}</h4>
                                <h6 style="font-size: 18px;">${signature.title}</h6>
                                <p>
                                    Osmangazi Mah. Mehmet Deniz Kopuz Cad.
                                    <br />
                                    No:21-23/1 Kıraç - Esenyurt / İstanbul
                                </p>
                                ${signature.mobile !== null && signature.mobile !== "null" && signature.mobile !== "" ?
                                    (`
                                        <p style = "margin: 0;" >
                                            G: +90 ${formatPhoneNumber(signature.mobile)}
                                        </p> 
                                    `) : ""
                                }
                                ${signature.mobile2 !== null && signature.mobile2 !== "null" && signature.mobile2 !== "" ?
                                    (`
                                        <p style = "margin: 0;" >
                                            G: +90 ${formatPhoneNumber(signature.mobile2)}
                                        </p> 
                                    `) : ""
                                }
                                <p style = "margin: 0;" >
                                    T: +90 ${formatPhoneNumber(signature.phone)}
                                </p>
                                <p>
                                    F: +90 ${formatPhoneNumber(signature.fax)}
                                </p>
                                <p>
                                    ${signature.email}
                                </p>
                            </div>
                            <div style="flex: 1; width: ${Math.floor(signatureWidth / 2) - 1}px; text-align: center; display: flex; flex-direction: column; align-items: center; justify-content: center; font-size: 12px; line-height: 1.5;">
                                <img src=${Logo} style="width: ${Math.floor(((signatureWidth / 2) - 1) / 100 * 80)}px; display: block; margin: 0 auto; object-fit: cover;" alt="Logo" />
                                <p style="margin-top: 20px; font-size: 14px;">camglass.com.tr</p>
                            </div>
                        </div>
                    `,
                    "signature"
                );
            };
            if (signatureStatus === "signature") {
                generateImageFromHTML(
                    `
                        <div style="display: flex; justify-content: center;">
                            <img src=${Facebook} alt="Facebook" style="width: 25px; margin-right: 2px;" />
                            <p style="font-size: 12px; margin: auto; padding-right: 10px;">${links[0].name}</p>
                        </div>
                    `,
                    "facebook"
                );
            };
            if (signatureStatus === "facebook") {
                generateImageFromHTML(
                    `
                        <div style="display: flex; justify-content: center;">
                            <img src=${LinkedIn} alt="LinkedIn" style="width: 25px; margin-right: 2px;" />
                            <p style="font-size: 12px; margin: auto; padding-right: 10px;">${links[1].name}</p>
                        </div>
                    `,
                    "linkedin"
                );
            };
            if (signatureStatus === "linkedin") {
                generateImageFromHTML(
                    `
                        <div style="display: flex; justify-content: center;">
                            <img src=${Instagram} alt="Instagram" style="width: 25px; margin-right: 2px;" />
                            <p style="font-size: 12px; margin: auto; padding-right: 10px;">${links[2].name}</p>
                        </div>
                    `,
                    "instagram"
                );
            };
            if (signatureStatus === "instagram") {
                generateImageFromHTML(
                    `
                        <div style="display: flex; justify-content: center;">
                            <img src=${Youtube} alt="YouTube" style="width: 25px; margin-right: 2px;" />
                            <p style="font-size: 12px; margin: auto; padding-right: 10px;">${links[3].name}</p>
                        </div>
                    `,
                    "youtube"
                );
            };
            if (signatureStatus === "youtube") {
                setSignatureStatus(null);
            }
        };
    }, [signature, links, signatureWidth, signatureStatus, image, base64, Logo, Facebook, LinkedIn, Instagram, Youtube]);

    useEffect(() => {
        if (signature && banners && signatureWidth) {
            if (signature.bannerID) {
                const banner = banners.find((banner) => banner.id === signature.bannerID);
                if (banner) {
                    const imageUrl = `${process.env.PUBLIC_URL}/uploads/banners/${banner.photoName}`;
                    convertBannerToBase64(imageUrl);
                };
            };
        };
    }, [signature, signatureWidth, banners]);

    if (signature && links && banners && kvkk && signatureStatus === null) {
        return (
            <>
                {`
                    <!DOCTYPE html>
                    <html lang="tr">
                        <head>
                            <meta charset="UTF-8">
                        </head>
                        <body>
                            <div style="display: flex; flex-direction: column; align-items: flex-start; background-color: white; padding: 30px 0;">
                                <a href="https://www.camglass.com.tr">
                                    <img src=${image.signature} alt="Signature" />
                                </a>
                                <!-- Social Media Links -->
                                <div style="display: flex; justify-content: center; width: ${(Math.floor(signatureWidth / 2) - 1)}; margin-top: 10px;">
                                    <div style="display: flex; justify-content: space-around; width: 100%; margin-left: 15px;">
                                        <a href=${links[0].link} style="text-align: center; height: 25px;">
                                            <img src=${image.facebook} alt="Facebook" />
                                        </a>
                                        <a href=${links[1].link} style="text-align: center; height: 25px;">
                                            <img src=${image.linkedin} alt="LinkedIn" />
                                        </a>
                                        <a href=${links[2].link} style="text-align: center; height: 25px;">
                                            <img src=${image.instagram} alt="Instagram" />
                                        </a>
                                        <a href=${links[3].link} style="text-align: center; height: 25px;">
                                            <img src=${image.youtube} alt="YouTube" />
                                        </a>
                                    </div>
                                </div>

                                <!-- Banner -->
                                ${signature.bannerID !== null && signature.bannerID !== "null" && signature.bannerID !== "" ? (`
                                    <div style="display: flex; justify-content: center; width: ${signatureWidth}; padding: 0 15px; margin-top: 30px;">
                                        <img src=${base64.banner} width=${signatureWidth} style="display: block;" alt="Banner" />
                                    </div>    
                                `) : ""}

                                <!-- KVKK -->
                                <div style="margin-top: 30px; padding: 0 15px;">
                                    ${kvkk.text1}
                                </div>
                            </div>
                        </body>
                    </html>
                `}
                <br/>
                <Button
                    variant="primary"
                    className="me-1 ms-4 mt-5"
                    style={{float: "right"}}
                    onClick={() => simulateCtrlA()}
                    disabled={isCopied}
                >
                    {
                        isCopied ?
                            <>
                                <i className="icofont-checked text-white me-1"></i>
                                Kopyalandı
                            </> :
                            <>
                                Kopyala
                            </>
                    }
                </Button>
            </>
        )
    }
}

export default HtmlPage;